<template>
  <v-app-bar
    id="core-app-bar"
    absolute
    app
    color="transparent"
    flat
    height="88"
    >
    <v-toolbar-title class="pt-6 tertiary--text font-weight-light align-self-center">
      <div v-if="user">
        <p>{{ user | displayName }}</p>
      </div>
      <div v-else>
        Please log in at <a href="/admin/login/?next=/admin/"
          target="_blank">EENAdmin</a>
      </div>
    </v-toolbar-title>
    <v-spacer></v-spacer>
    <v-btn icon title="Troubleshooting" href="https://eagleeyenetworks.atlassian.net/wiki/spaces/ENG/pages/2721644588/Troubleshooting+Failed+Asmodeus+Deployments" target="_blank">
      <v-icon color="white">mdi-help</v-icon>
    </v-btn>
    <v-btn icon title="Prometheus" href="https://graphs.eencloud.com/d/2XdjXB5Gz/kubernetes-resource-requests-limits-by-node?orgId=1&var-pod=test&var-node=All" target="_blank">
      <v-icon color="white">mdi-torch</v-icon>
    </v-btn>
    <v-btn icon title="Swagger" href="/swagger/" target="_blank">
      <v-icon color="white">mdi-api</v-icon>
    </v-btn>
    <v-btn icon title="ReDoc" href="/redoc/" target="_blank">
      <v-icon color="white">mdi-file-document</v-icon>
    </v-btn>
    <v-btn icon title="Admin" href="/admin/" target="_blank">
      <v-icon color="white">mdi-language-python</v-icon>
    </v-btn>
  </v-app-bar>
</template>

<script>
export default {
  data() {
    return {}
  },
  computed: {
    user() {
      return this.$store.state.user
    }
  },
  mounted() {
    this.$store.dispatch("getUser");
  }
}
</script>
